body {
  text-align: center;
  background-color: black;
}

.footer {
  position:fixed;
  font-family: 'Montserrat', sans-serif;
  bottom:0;
  height:100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  font-weight: bold;
  padding: 0 100px
}

.footer a:link{
  margin-left: 20px;
  color:white;
  align-self: center;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer a:visited{
  color:white;
}

.footer a>img {
  margin-right: 5px;
}

.MuiFormLabel-root.Mui-focused {
  color:'white' !important;
}